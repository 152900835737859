<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
    <div class="page-content w-100">
        <div class="row g-0">
  <div class="col-sm-12">
    <p class="text-center page-heading">User List</p>
  </div>
</div>
   <div class="row mt-2">
      <div class="col-12 col-lg-4 col-sm-6 col-md-6 mt-0" v-for="user in userarrayData" :key="user">
        <div class="border-right-color pe-4">
        <ul>
          <li class="list-style-none row">
            <div class="col-10 mb-0 col-lg-9">{{ user.firstName }} - {{ user.id }}</div>
            <div class="col-2 col-lg-3">
                  <span class='text-red-color' >
                    <font-awesome-icon :icon="['fas', 'times-circle']" @click="showPopupUser(user)" />
                  </span>

                  <div v-if="isPopupVisibleUser" class="doctorPopup">
      <div class="popup-content">
        <p>Do you want to opt out of community?</p>
        <div style="display: flex;justify-content: space-around;">
          <button @click="deleteUser()" class="yes-button">Yes</button>
          <button @click="closePopup" class="no-button">No</button>
        </div>
      </div>
    </div>
  </div>
  </li>
  </ul>
</div>
 </div>
</div>
</div>
</template>
<script>
import axios from 'axios';
export default {
components: {},
data() {
    return {
        userarrayData:[],
        isPopupVisibleUser:false,
        getUserID:null
    }
},
computed:{

},
created: async function () {
   await this.userData();
},
methods: {
    userData(){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
        .get(`${BASE_API_URL}/public-user`)
            .then(async (response) => {
                this.userarrayData =response.data
            })
    },
    showPopupUser(userdata) {
      this.getUserID=userdata
      this.isPopupVisibleUser = true;
    },
    deleteUser(){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;   
      if(this.getUserID?.id){
      axios
        .delete(`${BASE_API_URL}/public-user/${this.getUserID?.id}/public`)
        .then((response) => {
        if(response.data){
          this.getUserID=null
          this.userData();
          this.closePopup();
        }
        }) 
        .catch(function (error) {
          return error
        });
    }
    },
    closePopup() {
      this.isPopupVisibleUser = false;
    },
  }
}

</script>
<style>
.text-red-color{
  font-size:20px;
  color: #cd381c;
  cursor: pointer;
}
.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}
.doctorPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.yes-button,
.no-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.yes-button {
  background-color: #38761D;
  color: #fff;
}
.no-button {
  background-color: #FF9900;
  color: #fff;
}
</style>